import React, { useState } from 'react'
import { useEffect } from 'react'
import { ClienVerify } from '../../../http/index'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import successJson from '../../../images/success.json'
import Lottie from 'react-lottie'

const VerifyUserAccount = () => {

    let { token } = useParams();
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: successJson,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        const verifyUser = async () => {
            try {
                setLoading(true)
                const res = await ClienVerify(token)
                console.log(res)
                setMessage(res.data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.log(error)
            }
        }
        verifyUser()
    }, [])

    return (

        <div className="dark:bg-[#2c2c2c] dark:text-white relative h-screen flex justify-center align-middle items-center bg-no-repeat bg-center md:bg-object-scale-down bg-[url('https://res.cloudinary.com/mayurs-media/image/upload/v1693753508/mobile-login_kmuqyo.jpg')] md:bg-[url('https://res.cloudinary.com/mayurs-media/image/upload/v1693753508/mobile-login_kmuqyo.jpg')] md:bg-gray-400 md:bg-blend-multiply ">
            {
                loading
                    ?
                    <div className="bg-white p-16 rounded-lg shadow-lg">
                        <div className="rounded-full h-24 w-24 flex items-center justify-center mx-auto mb-4">
                            <div className="relative flex justify-center items-center">
                                <div className="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-[#C0A04C]"></div>
                                <img src="/images/logo/logo-main.png" className="h-16" />
                            </div>
                        </div>
                        <h1 className="text-center text-yellow-800 font-semibold text-4xl pt-6">Verifying ...</h1>
                    </div>
                    :
                    <div className="bg-white p-16 rounded-lg shadow-lg">
                        <div className="rounded-full h-24 w-24 flex items-center justify-center mx-auto mb-4">
                            <Lottie
                                options={defaultOptions}
                                height={100}
                                width={100}
                            />
                        </div>
                        <h1 className="text-center text-green-600 font-semibold text-4xl mb-2">Success</h1>
                        <p className="text-gray-700 text-xl">
                            {message}
                        </p>
                        <Link className='flex justify-center mt-5' to='/login'>
                            <button type="button" className="text-white bg-[#C0A04C] hover:bg-[#A48533] hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-[#C0A04C] dark:hover:bg-white dark:focus:ring-blue-800" >Log in</button>
                        </Link>
                    </div>
            }

        </div>
    )
}

export default VerifyUserAccount