import React from 'react'
import { useState, useRef } from 'react'
import parse from 'html-react-parser';
// import { HtmlParser } from 'html-react-parser';
import './accordian.css'

const Accordian = ({ title, isText, content, isOpened, onClick, color, textcol, contentfont }) => {


    const transform = (node) => {
        if (node.type === 'tag' && node.name === 'ul') {
            // Return a <div> that contains all `li` elements
            return (
                <div style={{ listStyleType: 'disc' }}>
                    {node.children.map((child, index) => {
                        if (child.type === 'tag' && child.name === 'li') {
                            // Render each <li> as is
                            return <li key={index} style={{ margin: '0 0' }}>{child.children[0].data}</li>;
                        }
                        return null;
                    })}
                </div>
            );
        }

        // Return the node unchanged if it doesn't match the criteria
        return undefined;
    };

    return (
        <div className='mb-6'>
            <div onClick={onClick} className={`cursor-pointer ${color}`}>
                <div className="flex justify-between align-middle">
                    <p className={`text-lg ${textcol}`}>{title}</p>
                    {isOpened ? (
                        <p className="font-bold text-xl">
                            -
                        </p>
                        // <img src="/images/icons/minus.svg" alt="Minus Icon" />
                    ) : (
                        <p className="font-bold text-xl">
                            +
                        </p>
                        // <img src="/images/icons/add.svg" alt="Add Icon" />
                    )}
                </div>
            </div>
            {isOpened && (
                <div id='accordiandiv' className={`transition-all duration-200 pl-3`}>

                    {
                        isText
                            ?
                            <>
                                {parse(content, { replace: transform })}
                            </>
                            :
                            <>
                            { content }
                            </>
                    }

                </div>
            )}
            <hr className='border-slate-300' />
        </div>
    )
}

export default Accordian