import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

// SortableItem Component with Remove Functionality
const SortableItem = SortableElement(({ image, index, removePhoto }) => (
    <div className="z-50 h-20 w-40 p-2 relative">
        <img onClick={(e) => e.stopPropagation()} src={image} alt="" className="object-cover w-full h-full rounded" />
        {/* Cancel button to remove the photo */}
        <button
            onClick={() => removePhoto(image)} // Call removePhoto when clicked
            className="absolute top-0 right-0 text-center text-white rounded-full  bg-red-500 h-5 w-5"
            type="button" // Ensure it's a button type
        >
           &#10005;
        </button>

    </div>
));

// SortableList Component
const SortableList = SortableContainer(({ items, removePhoto }) => (
    <div className="flex flex-wrap">
        {items.map((image, index) => (
            <SortableItem
                key={`item-${index}`}
                index={index}
                image={image}
                removePhoto={removePhoto} // Pass removePhoto to SortableItem
            />
        ))}
    </div>
), {
    shouldCancelStart: (e) => {
        // Prevent dragging if the target is the button or icon
        return e.target.tagName === 'BUTTON' || e.target.tagName === 'IMG';
    }
});

// Main Component
const MySortableComponent = ({ images, setImages }) => {
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const updatedImages = arrayMoveImmutable(images, oldIndex, newIndex);
        setImages(updatedImages);
    };

    // This function should be defined here, in MySortableComponent
    const removePhoto = (indexToRemove) => {
        setImages((prevImages) =>
            prevImages.filter((image, index) => image !== indexToRemove)
        );
        console.log("after", images)
    };

    return (
        <SortableList items={images} onSortEnd={onSortEnd} axis="xy" removePhoto={removePhoto} />
    );
};

export default MySortableComponent;