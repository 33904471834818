import React, { useState, useEffect } from 'react';
import { Popover, TextField, Divider, Button } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

function CalendarPopover({ onDateChange, defaultDate }) {
    const initialDate = defaultDate ? dayjs(defaultDate) : null;
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDate, setSelectedDate] = useState(initialDate);
    const [displayDate, setDisplayDate] = useState(
        initialDate ? initialDate.format("MMM D, YYYY h:mm A") : "Select Date & Time"
    );

    useEffect(() => {
        if (defaultDate) {
            const date = dayjs(defaultDate);
            setSelectedDate(date);
            setDisplayDate(date.format("MMM D, YYYY h:mm A"));
        }
    }, [defaultDate]);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleClear = () => {
        setSelectedDate(null);
        setDisplayDate("Select Date & Time");
    };

    const handleDateChange = (newDate) => {
        const date = dayjs(newDate);
        setSelectedDate(date);
        setDisplayDate(date.format("MMM D, YYYY h:mm A"));
    };

    const handleOk = () => {
        if (selectedDate) {
            onDateChange(selectedDate);
        }
        handleClose();
    };

    return (
        <div className='flex flex-col cursor-pointer'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className='px-0 py-0.5 w-full placeholder:text-sm bg-transparent text-sm font-medium' onClick={handleClick}>
                    {displayDate}
                </div>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <div className="p-4 text-center font-medium">
                        {selectedDate ? selectedDate.format("MMM D, YYYY h:mm A") : "Select Date & Time"}
                    </div>
                    <Divider />
                    <div className="flex justify-center items-start p-4">
                        <StaticDatePicker
                            displayStaticWrapperAs="desktop"
                            value={selectedDate}
                            minDate={dayjs()}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                        <div className="mt-7 text-center">
                            <div>Select Time</div>
                            <MultiSectionDigitalClock
                                value={selectedDate}
                                onChange={handleDateChange}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end p-4 space-x-2">
                        <Button variant="outlined" onClick={handleClear}>Clear</Button>
                        <Button variant="contained" onClick={handleOk}>OK</Button>
                    </div>
                </Popover>
            </LocalizationProvider>
        </div>
    );
}

export default CalendarPopover;
