import React, { memo } from 'react';
import NumberTicker from '../MagicUI/NumberTicker';
import { motion } from 'framer-motion';

const CategoryCard = ({ data }) => {
    return (
        <div className="rounded-lg overflow-hidden">
            <motion.div
                className="relative group rounded-lg"
                whileHover={{ scale: 1.1 }} // Zoom effect on hover
                transition={{ duration: 0.3 }}  // Smooth transition
            >
                <img
                    className="rounded-lg object-cover w-full h-64 md:h-72 snap-start"
                    src={data.photo}
                    alt=""
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black opacity-50  rounded-lg"></div>
            </motion.div>
            <div className="ml-2 absolute bottom-2 left-1 p-1 pl-2 pr-2">
                <p className="text-lg text-white font-medium">{data.categoryName}</p>
                <p className="w-28 dark:bg-[#2c2c2c] dark:text-white bg-white text-black rounded-md text-sm py-1 pl-1 pr-1 font-semibold text-center">
                    {/* {data.validOfferCount} */}
                    <NumberTicker value={data.validOfferCount} />
                    {data.validOfferCount > 1 ? <span className="ml-1">Offers</span> : <span className="ml-1">Offer</span>}
                </p>
            </div>
        </div>
    )
}

export default memo(CategoryCard);