import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DarkModeToggle from '../../DarkModeToggle/DarkModeToggle';


const UserMenu = ({ isOpen, toggleDropdown, dropdownRef, userLogout }) => {
    const navigate = useNavigate();

    return (
        <div className='space-x-2 flex align-middle items-center justify-center'>
            <div className='flex justify-center'>
                <DarkModeToggle />
            </div>
            <button className='relative' onClick={toggleDropdown}>
                <img className='m-1 h-10' src="/images/icons/navprofile.png" alt="Profile" />
                {isOpen && (
                    <div
                        className="origin-top-left right-0 absolute mt-0 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 w-52 z-50 dark:bg-[#2c2c2c]"
                        ref={dropdownRef}
                    >
                        <div className="px-3 flex flex-col">
                            <ul className="py-2 text-base text-black dark:text-gray-200" aria-labelledby="doubleDropdownButton">
                                <Link to={
                                    window.location.pathname.includes('/vendor/')
                                        ?
                                        `/vendor/my-account`
                                        :
                                        `/my-account`
                                }>
                                    <a href="#" className="flex block px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white align-middle items-center">
                                        <img src="/images/icons/user.svg" className="dark:hidden flex h-4 mr-2 fill-current text-gray-900 dark:text-white" alt="User" />
                                        <img src="/images/icons/user-light.svg" className="dark:flex hidden h-4 mr-2 fill-current text-gray-900 dark:text-white" alt="User Light" />
                                        Profile
                                    </a>
                                </Link>
                                <Link to="/favorites">
                                    <button className='flex align-middle items-center w-full block px-4 py-2 hover:bg-gray-100 text-left dark:hover:bg-gray-600 dark:hover:text-white'>
                                        <img src="/images/icons/heartNav.svg" className='dark:hidden flex h-4 mr-2' alt="Favorites" />
                                        <img src="/images/icons/heartNav-light.svg" className='dark:flex hidden h-4 mr-2' alt="Favorites Light" />
                                        Favorites
                                    </button>
                                </Link>
                                <Link to="/user/notification">
                                    <button className='flex align-middle items-center w-full block px-4 py-2 hover:bg-gray-100 text-left dark:hover:bg-gray-600 dark:hover:text-white'>
                                        <i class="text-xl ri-notification-3-line mr-2 "></i>
                                        Notification
                                    </button>
                                </Link>
                                <Link to="/pastpurchase">
                                    <button className='flex align-middle items-center w-full block px-4 py-2 hover:bg-gray-100 text-left dark:hover:bg-gray-600 dark:hover:text-white'>
                                        <img src="/images/icons/pastpurchased.svg" className='dark:hidden flex h-4 mr-2' alt="Past Purchased" />
                                        <img src="/images/icons/pastpurchased-light.svg" className='dark:flex hidden h-4 mr-2' alt="Past Purchased Light" />
                                        Past Purchased
                                    </button>
                                </Link>
                                <button onClick={userLogout} className='flex align-middle items-center w-full block px-4 py-2 hover:bg-gray-100 text-left dark:hover:bg-gray-600 dark:hover:text-white'>
                                    <img src="/images/icons/log-out-light.svg" className='dark:flex hidden h-4 mr-2' alt="Logout" />
                                    <img src="/images/icons/log-out.svg" className='dark:hidden flex h-4 mr-2' alt="Logout" />
                                    Logout
                                </button>
                            </ul>
                        </div>
                    </div>
                )}
            </button>
        </div>
    );
};

export default UserMenu;
