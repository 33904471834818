import React from 'react'
import { Link } from 'react-router-dom'

const FeatureImage = () => {
    return (
        <Link to='/category/omannationalday'>
            <section className='flex justify-center items-center align-middle py-5'>
                <section className='w-full md:w-full sm:mx-5 lg:w-10/12 md:mx-5 md:w-10/12 xl:w-9/12 2xl:w-7/12'>
                    <img className='hidden md:flex' src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/omannationalday.png" alt="" />
                    <img className='mx-auto flex md:hidden' src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/omannationaldaymobile.png" alt="" />
                </section>
            </section>
        </Link>
    )
}

export default FeatureImage