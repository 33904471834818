import React, { useState } from 'react'
import { SendVerificationLink } from '../../../http/index'
import toast, { Toaster } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom'

const InputEmail = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')

    async function submit() {
        if (!email) {
            return toast.error("Required field is missing")
        }
        try {
            setLoading(true)
            const submitdata = {
                email: email
            }
            const { data } = await SendVerificationLink(submitdata)
            await toast.success(data.data)
            setTimeout(() => {
                if (window.location.href.includes('/vendor/')) {
                    navigate(`/vendor/login`)
                } else {
                    navigate(`/login`)
                }
            }, 3000);
            setLoading(false)
            console.log(data)
        } catch (error) {
            setLoading(false)
            toast.error(error.response.data.data)
            // console.log(error)
        }
    }

    return (
        <body className="dark:bg-[#2c2c2c] dark:text-white relative h-screen flex justify-center align-middle items-center bg-no-repeat bg-center md:bg-object-scale-down bg-[url('https://res.cloudinary.com/mayurs-media/image/upload/v1693753508/mobile-login_kmuqyo.jpg')] md:bg-[url('https://res.cloudinary.com/mayurs-media/image/upload/v1693753508/mobile-login_kmuqyo.jpg')] md:bg-gray-400 md:bg-blend-multiply ">
            <button className='absolute top-10 left-10'>
                <img onClick={(() => navigate(-1))} src="/images/icons/login-back.svg" alt="" />
            </button>
            <Toaster />
            <div className='flex-row items-center align-middle '>
                <div className="max-w-lg mx-auto bg-white dark:bg-[#2c2c2c] p-7 rounded-xl shadow shadow-slate-300 flex-row align-middle items-center">
                    <h1 className="text-4xl font-medium">Reset password</h1>
                    <p className="text-slate-500">Fill up the form to reset the password</p>

                    <div action="" className="my-10">
                        <div className="flex flex-col space-y-5">
                            <label for="email">
                                <p className="font-medium text-slate-700 dark:text-slate-300 pb-2">Email address</p>
                                <input id="email" name="email" type="email" className="w-full rounded-md border-slate-500 focus:outline-0 focus:ring-[#454545]" placeholder="Enter email address"
                                    onChange={((e) => setEmail(e.target.value))} />
                            </label>

                            {
                                loading
                                    ?
                                    <button className="flex justify-center items-center w-full  p-2 bg-[#C0A04C] hover:bg-[#A48533] rounded-md text-sm font-bold text-gray-50 transition duration-200">
                                        <img className='h-6' src="/images/icons/button-loading.svg" alt="" />
                                    </button>
                                    :
                                    <button className="w-full p-2 bg-[#C0A04C] hover:bg-[#A48533] rounded-md text-sm font-bold text-gray-50 transition duration-200" onClick={submit}>Reset Password</button>

                            }
                            <p className="text-center">Not registered yet?
                                <Link to="/sign-up" className="text-[#C0A04C]  font-medium inline-flex space-x-1 items-center"><span>Register now </span><span><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                </svg></span>
                                </Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    )
}

export default InputEmail