import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const SearchBox = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState();

    return (
        <div className='hidden md:flex md:relative mx-auto z-50 bg-white dark:border-0 border-2 w-11/12 md:w-2/3 flex items-center justify-center flex-col  rounded-lg dark:bg-[#2c2c2c] z-100'>
            <div className='z-50 w-full text-center dark:bg-[#2c2c2c] bg-white rounded-lg p-3'>
                <span className='text-xl font-bold'>
                    Where To ?
                </span>

            </div>
            <div className='absolute -top-14 -right-28 z-0'>
                <img src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/rightflag.png" alt="" />
            </div>
            <div className='absolute -top-14 -left-28 z-0'>
                <img src="https://muscat-media.nyc3.cdn.digitaloceanspaces.com/assets/leftflag.png" alt="" />
            </div>
            <form onSubmit={(e) => {
                e.preventDefault();
                if (query.trim() !== '') {
                    navigate(`/category/events%20&%20offers?search=${query}`);
                }
            }} className='flex w-full justify-center'>
                <div className='w-full flex grow '>
                    <input onChange={((e) => setQuery(e.target.value))} type="text" id="default-input" placeholder='Search on Muscat Where To...' className="bg-[#E7E7E7] border border-gray-300 focus:ring-[#C0A04C] focus:border-[#C0A04C] text-gray-900 text-xs rounded-lg block md:w-full p-3 mx-3 dark:bg-[#454545] dark:border-0 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#C0A04C] dark:focus:border-[#C0A04C]" />
                </div>

                <div className='flex mx-auto justify-center pr-2'>
                    <button
                        type="submit" className="shadow-lg shadow-cyan-500/25 align-middle text-white bg-[#C0A04C] hover:bg-[#A48533] hover:text-white focus:[#A48533] font-medium rounded-lg text-sm px-8 py-2 text-center mr-3 md:mr-0 flex align-middle justify-center items-center">
                        <img src="/images/icons/search1.svg" className="h-4 w-4" alt="" />
                        <span className='ml-1 text-base'>Search</span>
                    </button>
                </div>
            </form>
            <div className='flex flex-wrap align-middle items-center justify-between mt-5 w-full pb-3'>
                <div className='pl-4'>
                    <p className='text-base hidden font-bold text-md md:block text-left align-middle mb-1'>
                        Popular Searches
                    </p>
                    <div className='hidden md:flex justify-between space-y-2 md:space-x-52 overflow-x'>
                        <div className='flex flex-wrap justify-center md:space-x-2' style={{ overflowX: 'auto' }}>
                            <Link className='my-1' to='/category/eat&drink?subcategory=Friday Brunch'>
                                <button className='rounded-full border border-black hover:border-[#A48533] hover:bg-[#A48533] dark:border-white pr-4 pl-4 h-6 text-sm font-normal hover:text-white'>Friday Brunch</button>
                            </Link>
                            <Link className='my-1' to='category/eat&drink?subcategory=Ladies%20Night'>
                                <button className='rounded-full border border-black hover:border-[#A48533] hover:bg-[#A48533] dark:border-white pr-4 pl-4 h-6 text-sm font-normal hover:text-white'>Ladies Night</button>
                            </Link>
                            <Link className='my-1' to='/category/eat&drink?subcategory=Afternoon%20Tea'>
                                <button className='rounded-full border border-black hover:border-[#A48533] hover:bg-[#A48533] dark:border-white pr-4 pl-4 h-6 text-sm font-normal hover:text-white'>Afternoon Tea</button>
                            </Link>
                            <Link className='my-1' to='/category/eat&drink?subcategory=Dinner&day=Thursday'>
                                <button className='rounded-full border border-black hover:border-[#A48533] hover:bg-[#A48533] dark:border-white pr-4 pl-4 h-6 text-sm font-normal hover:text-white'>Thursday Dinner</button>
                            </Link>
                            <Link className='my-1' to='/category/eat&drink?subcategory=Happy%20Hour'>
                                <button className='rounded-full border border-black hover:border-[#A48533] hover:bg-[#A48533] dark:border-white pr-4 pl-4 h-6 text-sm font-normal hover:text-white'>Happy Hour</button>
                            </Link>
                            <Link className='my-1' to='/category/pool&beach'>
                                <button className='rounded-full border border-black hover:border-[#A48533] hover:bg-[#A48533] dark:border-white pr-4 pl-4 h-6 text-sm font-normal hover:text-white'>Pool And Beach</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchBox;
