import React from 'react'
import Navbar from '../../../components/shared/Navbar/Navbar'
import Footer from '../../../components/shared/Footer/Footer'
import { Link, useNavigate } from 'react-router-dom'

const Cookies = () => {

    const navigate = useNavigate()

    return (
        <div className='dark:bg-[#2c2c2c] dark:text-white'>
            <Navbar />
            <section className='md:mr-48 md:ml-48 mt-5 ml-6 mr-6'>
                <div className='flex align-middle items-center  justify-between'>
                    <div className="flex align-middle items-center">
                        <button onClick={() => navigate(-1)} className=' mt-1'>
                            <img className='h-14 w-14' src="/images/icons/back-button.png" alt="" />
                        </button>
                        <p className='text-xl font-bold'>Cookies Policy - MWT</p>
                    </div>
                </div>
                <div className='w-full mx-5'>
                    <div id="privacy-policy" className="mx-auto p-8">
                        <div id="intro">
                            <p className="mb-4 font-semibold">Last updated 13/5/2022.</p>

                            <p className="mb-4">
                                This Cookies Policy explains what Cookies are and how We use them. You should read this policy so You can understand what type of cookies We use, or the information We collect using Cookies and how that information is used.
                                <br /><br />
                                Cookies do not typically contain any information that personally identifies a user, but personal information that we store about. You may be linked to the information stored in and obtained from Cookies. For further information on how We use, store and keep your personal data secure, see our Privacy Policy.
                                <br /><br />
                                We do store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies We use. This Cookies Policy was generated by TermsFeed Cookies Policy Template.
                                <br /><br />
                                <span className="ml-0 font-semibold">Interpretation and Definitions</span>
                                <br /><br />
                                <span className="ml-0 font-semibold">Interpretation</span>
                                <br />
                                The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                                <br /><br />
                                <span className="ml-0 font-semibold">Definitions</span>
                                <br />
                                For the purposes of this Cookies Policy:
                                <br /><br />
                                <ul>
                                    <li className='list-disc'>
                                        <span className="ml-0 font-semibold">Company </span>
                                        (referred to as either “the Company”, “We”, “Us” or “Our” in this Cookies Policy) refers to IBI- Integrated Business International
                                    </li>
                                    <li className='list-disc'>
                                        <span className="ml-0 font-semibold">Cookies </span>
                                        means small files that are placed on Your computer, mobile device or any other device by a website, containing details of your browsing history on that website among its many uses.
                                    </li>
                                    <li className='list-disc'>
                                        <span className="ml-0 font-semibold">Website </span>
                                        refers to Muscat Where To?, accessible from muscatwhereto.com
                                    </li>
                                    <li className='list-disc'>
                                        <span className="ml-0 font-semibold">You </span>
                                        means the individual accessing or using the Website, or a company, or any legal entity on behalf of which such individual is accessing or using the Website, as applicable.
                                    </li>
                                </ul>
                                The use of the Cookies
                                <br /><br />
                                Type of Cookies We Use
                                <br /><br />
                                Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.
                                <br /><br />
                                We use both session and persistent Cookies for the purposes set out below:
                                <br />
                                <ul>
                                    <li className='list-disc'>
                                        <span className="ml-0 font-semibold">Necessary / Essential Cookies Type: </span>
                                        Session Cookies Administered by: Us Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
                                    </li>
                                    <li className='list-disc'>
                                        <span className="ml-0 font-semibold">Cookies Policy / Notice Acceptance Cookies Type: </span>
                                        Persistent Cookies Administered by: Us Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
                                        means small files that are placed on Your computer, mobile device or any other device by a website, containing details of your browsing history on that website among its many uses.
                                    </li>
                                    <li className='list-disc'>
                                        <span className="ml-0 font-semibold">Functionality Cookies Type:  </span>
                                        Persistent Cookies Administered by: Us Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.
                                    </li>
                                    <li className='list-disc'>
                                        <span className="ml-0 font-semibold">Tracking and Performance Cookies Type:  </span>
                                        Persistent Cookies Administered by: Third-Parties Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new advertisements, pages, features or new functionality of the Website to see how our users react to them.
                                    </li>
                                </ul>
                                <br /><br />
                                <span className="font-semibold ml-0">
                                    Your Choices Regarding Cookies
                                </span>


                                If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.
                                <br /><br />
                                If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly.
                                <br /><br />
                                If You’d like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help pages of your web browser.
                                <br /><br />
                                <ul>
                                    <li>
                                        For the Chrome web browser, please visit this page from Google: https://support.google.com/accounts/answer/32050
                                    </li>
                                    <li>
                                        For the Internet Explorer web browser, please visit this page from Microsoft: http://support.microsoft.com/kb/278835
                                    </li>
                                    <li>
                                        For the Firefox web browser, please visit this page from Mozilla: https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                                    </li>
                                    <li>
                                        For the Safari web browser, please visit this page from Apple: https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                                    </li>
                                    <li>
                                        For any other web browser, please visit your web browser’s official web pages.
                                    </li>
                                </ul>
                                <br />
                                Contact Us
                                <br />
                                If you have any questions about this Cookies Policy, You can contact us:
                                <br />
                                By visiting this page on our website: https://muscatwhereto.com/contact-us/
                            </p>

                        </div>
                    </div>

                </div>
            </section >
            <div className=''>
                < Footer />
            </div>
        </div>
    )
}

export default Cookies