// ErrorFallback.js
import React from 'react';
import { saveSettings } from '../../http';

const ErrorFallback = ({ error, resetErrorBoundary }) => {

    React.useEffect(() => {
        const sendErrorDetails = async () => {
            try {
                await saveSettings({
                    message: error.message,
                    stack: error.stack,
                })
            } catch (sendError) {
                console.error('Error reporting failed:', sendError);
            }
        };

        sendErrorDetails();
    }, [error]);

    return (
        <div role="alert" className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
            <div className="max-w-md p-8 bg-white shadow-lg rounded-lg text-center">
                <h2 className="text-2xl font-bold text-red-600 mb-4">Oops! Something went wrong</h2>
                <p className="text-gray-600 mb-6">
                    We're sorry, but it looks like we hit a snag. Our team is already on it!
                </p>
                <button
                    onClick={resetErrorBoundary}
                    className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-300"
                >
                    Try Again
                </button>
            </div>
        </div>
    );
};

export default ErrorFallback;
