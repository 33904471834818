const Features = {
    list: [
        "Shisha",
        "Rooftop",
        "Pool",
        "Beach",
        "Kids Friendly",
        "Pet Friendly",
        "Vegan/Vegetarian",
        "Live entertainment",
        "Ladies only",
        "Outdoor",
        "21+",
        "Cash Only",
        "Italian Cuisine",
        "Indian Cuisine",
        "Asian Cuisine",
        "Latin American Cuisine",
        "Seafood",
        "Middle Eastern",
        "Mediterranean Cuisine",
        "BBQ",
        "Brunch",
        "Sushi",
        "Indoor",
        "Majlis",
        "Mini Golf",
        "Water Park",
        "Golf",
        "Spa",
        "Infinite Pool",
        "Indoor Pool",
        "Snacks",
        "Cigar Lounge",
        "Happy Hour",
        "View"
    ],
};

const config = {
    placeholder: "Enter Event Specific Details in brief..",
    buttons: [
        'bold',
        'strikethrough',
        'underline',
        'italic', '|',
        'font',
        'fontsize',
        'align', 'undo', 'redo', '|',
        'hr',
        'print',
    ],
    buttonsMD: [
        'bold',
        'strikethrough',
        'underline',
        'italic', '|',
        'font',
        'fontsize',
        'align', 'undo', 'redo', '|',
        'hr',
        'print',
    ],
    buttonsSM: [
        'bold',
        'strikethrough',
        'underline',
        'italic', '|',
        'font',
        'fontsize',
        'align', 'undo', 'redo', '|',
        'hr',
        'print',
    ],
    buttonsXS: [
        'bold',
        'strikethrough',
        'underline',
        'italic', '|',
        'font',
        'fontsize',
        'align', 'undo', 'redo', '|',
        'hr',
        'print',
    ],
}

const popup_id = '66a028a78a781a068ee8bdea'
export { Features, config, popup_id };
