import React from 'react';

const PopupBox = ({ data, width = 72}) => {
    console.log(data)
    return (
        <>
            <div className={`w-${width} card flex flex-col`}>
                {
                    data.photo && (
                        <div className="img">
                            <img
                                className={`object-cover w-${width} rounded-t-lg aspect-square`}
                                src={data.photo}
                                alt=""
                            />
                        </div>
                    )
                }
                <div className="content h-auto py-7 bg-white rounded-b-md flex flex-col justify-center items-center">
                    <h2 className='text-2xl dark:text-black font-bold'>{data.title}</h2>
                    <h3 className='p-3 dark:text-black text-center text-wrap w-full break-words'>
                    {data.description}
                    </h3>
                    <a className='w-1/2 bg-[#C0A04C] hover:bg-[#A48533] px-2 py-1 rounded-md text-white font-bold flex justify-center ' href={data.ctaLink}>
                        {data.ctaText}
                    </a>
                </div>
            </div>
        </>
            );
};

export default PopupBox;
