import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { ClientProfileApi, ClientUpdateProfileApi, deleteUser, getCustomersSavedCards } from '../../../http/index'
import Navbar from '../../../components/shared/Navbar/Navbar';
import BottomNav from '../../../components/shared/BottomNav/BottomNav';
import { useDispatch, useSelector } from 'react-redux'
import { setAuth } from '../../../store/authSlice'

const WebProfile = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [response, setReponse] = useState({});
    const [refresh, setRefresh] = useState(false)
    const [firstname, setFirstname] = useState('')
    const [photo, setPhoto] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const isShareSupported = navigator.share !== undefined;

    const handleShare = async () => {
        try {
            if (window.isNative) {
                // Send a message to the native environment to trigger native sharing
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    action: 'share',
                    data: {
                        title: 'Muscat Where To',
                        text: 'Check out this awesome app!',
                        url: 'https://muscatwhereto.com',
                    }
                }));
            } else {
                // Web sharing
                await navigator.share({
                    title: 'Muscat Where To',
                    text: 'Check out this awesome app!',
                    url: 'https://muscatwhereto.com',
                });
            }
        } catch (error) {
            console.error('Error sharing:', error.message);
        }
    };


    const rateus = async () => {
        try {
            // Send a message to the native environment to trigger native sharing
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'rateUs',
            }));
        } catch (error) {
            console.error('Error sharing:', error.message);
        }
    };

    const deleteUserAccount = async (id) => {
        const res = await deleteUser(id)
        dispatch(setAuth(res.data));
        setRefresh(!refresh)
        navigate('/')
    }

    useEffect(() => {
        const fetchdata = async () => {
            try {
                setIsLoading(true)
                const { data } = await ClientProfileApi()
                console.log(data.data)
                setReponse(data)

                const { data: res } = await getCustomersSavedCards()

                console.log(res)
                setIsLoading(false)
                // setSavedCards(res.data)
                setFirstname(data.data.firstname)
                setLastname(data.data.lastname)
                setEmail(data.data.email)
            } catch (error) {
                setIsLoading(false)
                console.log(error)
                // toast.error(error.response.data.data)
            }
        }

        fetchdata()
    }, [refresh]);

    return (
        <div>
            <Navbar />
            {
                window.isNative && (
                    <>
                        <section className='h-screen dark:bg-[#2c2c2c] dark:text-white'>
                            <div className="header flex justify-between items-center align-middle">
                                {
                                    !isLoading && response.data == null
                                        ?
                                        <div onClick={() => navigate('/login')} className='m-3 rounded-lg w-full h-28 bg-gray-200 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 flex justify-center items-center'>
                                            <i className="text-[#A48533] text-3xl ri-lock-fill"></i>
                                            <span className='text-xl ml-2'>Login to continue</span>
                                        </div>


                                        :
                                        <div onClick={() => navigate('/my-account')} className='mt-3 ml-5 mr-5 flex align-middle items-center'>
                                            <div className="m-3 profile h-auto rounded-full">
                                                {
                                                    response.data != null
                                                        ?
                                                        <img src={response.data.photo || "/images/assets/profile.png"} alt="profile-photo" className='h-14 w-14 object-cover rounded-full' />
                                                        :
                                                        <img src="/images/assets/profile.png" alt="profile-photo" className='h-14 w-14 object-cover rounded-full' />
                                                }
                                            </div>

                                            <div className="name flex flex-col">
                                                {
                                                    response.data != null && (
                                                        <span className='font-bold text-xl'>
                                                            {response.data.username}
                                                        </span>
                                                    )
                                                }
                                                {
                                                    response.data != null && (
                                                        <span className='font-light text-sm'>
                                                            {response.data.email}
                                                        </span>
                                                    )
                                                }
                                            </div>
                                        </div>
                                }
                            </div>

                            <div className="ml-9 mr-9">
                                <ul className=''>
                                    <button onClick={() => navigate('/favorites')} className='w-full border border-t-0 border-r-0 border-l-0 py-4 border-b-white flex align-middle items-center'>
                                        <i class="text-xl ri-heart-line"></i>
                                        <span>Favorites</span>
                                    </button>

                                    <button onClick={() => rateus()} className='w-full border border-t-0 border-r-0 border-l-0 py-4 border-b-white flex align-middle items-center'>
                                        <i class="text-xl ri-star-line"></i>
                                        <span>Rate us</span>
                                    </button>

                                    <button onClick={() => navigate('/user/privacypolicy')} className='w-full border border-t-0 border-r-0 border-l-0 py-4 border-b-white flex align-middle items-center'>
                                        <i class="text-xl ri-shield-star-line"></i>
                                        <span>Privacy Policy</span>
                                    </button>

                                    <button onClick={() => handleShare()} className='w-full border border-t-0 border-r-0 border-l-0 py-4 border-b-white flex align-middle items-center'>
                                        <i class="text-xl ri-share-line"></i>
                                        <span>Invite a Friend</span>
                                    </button>

                                    <button onClick={() => navigate('/about')} className='w-full border border-t-0 border-r-0 border-l-0 py-4 border-b-white flex align-middle items-center'>
                                        <i class="text-xl ri-information-line"></i>
                                        <span>About Us</span>
                                    </button>

                                    {response.data != null && (
                                        <button onClick={() => deleteUserAccount(response.data._id)} className='w-full border border-t-0 border-r-0 border-l-0 py-4 border-b-white flex align-middle items-center'>
                                            <i class="ri-delete-bin-6-line"></i>
                                            <span>Delete Account</span>
                                        </button>
                                    )
                                    }
                                </ul>
                            </div>
                            {
                                isLoading == null && (
                                    <div className='h-screen w-full flex justify-center align-middle items-center'>
                                        <div className="relative flex justify-center items-center">
                                            <div className="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-[#C0A04C]"></div>
                                            <img src="/images/logo/logo-main.png" className="h-16" />
                                        </div>
                                    </div>
                                )
                            }
                        </section>

                    </>
                )
            }
            <BottomNav />
        </div>
    )
}

export default WebProfile